import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

import {
  AccountActions,
  AccountMenu,
  useSessionProfile,
} from "@saas/account/feature";
import { LoginForm } from "@saas/account/feature/login-form/login-form";
import {
  accessRouteMapping,
  SessionMetadata,
  sortRoute,
} from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { AppHead, BaseLayout, Header, LeftNavBar } from "@saas/layout/ui";
import { getMenuItems } from "@saas/layout/utils";
import { getResellerProfile } from "@saas/marketplace-connection/data-access";
import { NotificationBar, ScheduleDemoButton } from "@saas/shared/ui";
import { useSafePush } from "@saas/shared/utils";

import { NextPageWithLayout } from "../_app";

import { Session } from "@ory/client";

export const LoginPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { profile } = useSessionProfile();
  const { safePush } = useSafePush();

  const isMarket = !!router.query.login_challenge;

  const redirectLoggedIn = useCallback(
    (data?: { session: Session }) => {
      if (router.query.redirect) {
        return safePush(router.query.redirect as string);
      }

      if (
        data?.session?.identity?.metadata_public &&
        data.session.identity.metadata_public["accesses"]
      ) {
        const metadata = data.session.identity
          .metadata_public as SessionMetadata;
        const subaccountAccesses = sortRoute(metadata.accesses);

        const sortAccesses = (accesses) => {
          return accesses.sort((a, b) => {
            if (a === "chat:all") return 1;
            if (b === "chat:all") return -1;
            // For other elements, maintain their order
            return 0;
          });
        };

        const accessesSorted = sortAccesses(subaccountAccesses);

        const parentUserId = metadata.parent_user_id;
        const firstRoute = accessRouteMapping[accessesSorted[0]];

        if (parentUserId !== null && firstRoute) {
          return safePush(firstRoute);
        }
      }

      return safePush("/");
    },
    [router, safePush]
  );

  const loginMessage = (
    <span className={"flex flex-col pr-10"}>
      <span>Jika kamu sudah memiliki akun Melaka,</span>
      <span>silakan lakukan login terlebih dahulu.</span>
    </span>
  );

  const loginSubtitle = isMarket ? (
    <NotificationBar message={loginMessage} variant={"warning"} />
  ) : (
    <p className={"body-b1 text-he"}>
      Selamat datang kembali! Masukkan email dan kata sandi terdaftarmu.
    </p>
  );

  useEffect(() => {
    if (profile && !isMarket) {
      redirectLoggedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  return (
    <>
      <AppHead>Login</AppHead>

      <div className={"flex h-full justify-center md:items-center"}>
        <div
          className={"bg-neutral-N0 mx-6 mt-5 h-max rounded-2xl p-6 md:p-24"}
        >
          <div className={"flex flex-col gap-4 md:max-w-[22.5rem] md:gap-6"}>
            <div className={"flex flex-col gap-4"}>
              <div data-testid={"login_text__title"}>
                <h1 className={"title-large"}>Login</h1>
              </div>

              <div data-testid={"login_text__subtitle"}>{loginSubtitle}</div>
            </div>

            <LoginForm
              testid={"login"}
              getOauthProfile={getResellerProfile}
              onSuccess={redirectLoggedIn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

LoginPage.getLayout = (page, _, flags) => {
  return (
    <BaseLayout
      className={"bg-blue-B500"}
      header={
        <Header
          isAuthenticated={false}
          menuItems={getMenuItems(flags)}
          leftNavBar={
            <LeftNavBar menuItems={getMenuItems(flags)} whiteMenu={true} />
          }
          rightNavBar={
            <AccountActions accountMenu={AccountMenu} showActionButtons={false}>
              <div className={"hidden md:flex"}>
                <ScheduleDemoButton
                  phoneNumber={env.SUPPORT_PHONE_NO}
                  variant={"primary"}
                />
              </div>
            </AccountActions>
          }
          sticky={true}
          transparent={true}
          whiteMenu={true}
        />
      }
    >
      {page}
    </BaseLayout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

export default LoginPage;
